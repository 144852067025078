import { FC } from 'react';
import { Typography } from 'dekube-ui-kit';

import styles from './styles.module.scss';

export interface JettonProps {
  icon: string;
  title: string;
  subtitle: string;
}
export const Jetton: FC<JettonProps> = ({ icon, subtitle, title }) => (
  <div className={styles.jetton}>
    <img className={styles.jetton__img} src={icon} alt={title} />
    <div>
      <Typography className={styles.jetton__title} variant="s-medium">
        {title}
      </Typography>
      <Typography
        className={styles.jetton__subtitle}
        color="gray-400"
        variant="s-regular">
        {subtitle}
      </Typography>
    </div>
  </div>
);
