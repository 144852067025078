import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'dekube-ui-kit';

import { Jetton, JettonSkeleton, TOKENS_NAMES } from '@/shared';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.scss';

export const SupportedAssets: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getBlockchainState(),
    queryKey: [QueryKey.BlockchainState],
    select: (data) => data?.['supported-assets'],
  });

  return (
    <div className={styles.supportedAssets}>
      <Typography
        className={styles.supportedAssets__title}
        color="gray-400"
        variant="m-medium">
        {t('titles.supportedAssets')}
      </Typography>
      <div className={styles.supportedAssets__content}>
        {isLoading ? (
          <>
            {Array.from({ length: 9 })
              .fill(1)
              .map((_, idx) => (
                <JettonSkeleton key={idx} />
              ))}
          </>
        ) : (
          <>
            {data?.map((el) => (
              <Jetton
                key={el.code}
                title={TOKENS_NAMES[el.name].name || ''}
                icon={`/tokens/${el.name}.svg`}
                subtitle={TOKENS_NAMES[el.name].fullname || ''}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
