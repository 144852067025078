import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NavItem, useRouterPath } from '@/shared';
import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export interface NavigationProps {}
export const Navigation: FC<NavigationProps> = () => {
  const { t } = useTranslation();
  const { checkActivePath } = useRouterPath();

  return (
    <nav className={styles.navigation}>
      <NavItem
        to={RouterName.Explorer}
        label={t('labels.explorer')}
        active={checkActivePath(RouterName.Explorer)}
      />
      <NavItem
        to={RouterName.BlocksList}
        label={t('labels.blocks')}
        active={checkActivePath(RouterName.BlocksList)}
      />
      <NavItem
        to={RouterName.TransactionsList}
        label={t('titles.transactions')}
        active={checkActivePath(RouterName.TransactionsList)}
      />
      <NavItem
        to={RouterName.WalletsList}
        label={t('titles.wallets')}
        active={checkActivePath(RouterName.WalletsList)}
      />
      <NavItem
        to={RouterName.Stats}
        label={t('labels.statistic')}
        active={checkActivePath(RouterName.Stats)}
      />
    </nav>
  );
};
