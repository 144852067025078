export enum QueryKey {
  HistoryTrading = 'history-trading',
  BlockchainState = 'blockchain-state',
  BlockchainInfo = 'blockchain-info',
  Blocks = 'blocks',
  Block = 'block',
  BlocksList = 'blocks-list',
  TransactionsList = 'transactions-list',
  WalletsList = 'wallets-list',
  Transaction = 'transaction',
  LastBlock = 'last-block',
  Rounds = 'rounds',
  Nodes = 'nodes',
  Wallet = 'wallet',
}
