import { FC } from 'react';
import { formatAmount, Typography } from 'dekube-ui-kit';

import { useAssets } from '@/shared';

import styles from './styles.module.scss';

export interface WalletBalanceItemProps {
  code: string;
  amount: number;
}
export const WalletBalanceItem: FC<WalletBalanceItemProps> = ({
  amount,
  code,
}) => {
  const { getNameByCode } = useAssets();

  return (
    <div className={styles.walletBalanceItem}>
      <Typography variant="l-medium" color="blue-main">
        {getNameByCode(code)}
      </Typography>
      <Typography variant="l-medium" color="black">
        {formatAmount(amount)}
      </Typography>
    </div>
  );
};
