import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from 'dekube-ui-kit';

import { getAmountByAsset, useAssets } from '@/shared';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { CardRow } from '@shared/ui/CardRow/CardRow';
import { useQuery } from '@tanstack/react-query';

export interface TransactionInfoProps {
  id: number;
}
export const TransactionInfo: FC<TransactionInfoProps> = ({ id }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryFn: () => api.getTransactionById({ id }),
    queryKey: [QueryKey.Transaction, id],
  });
  const { getNameByCode } = useAssets();
  const hasAmount =
    data?.description &&
    !isLoading &&
    ('asset' in data.description || 'amount' in data.description);
  const amountData = hasAmount
    ? // @ts-ignore
      getAmountByAsset(data.description?.amount || data.description?.asset)
    : null;

  return (
    <div>
      <CardRow name="ID" value={data?.ID} wide loading={isLoading} />
      <CardRow
        name={t('labels.transactionType')}
        value={data?.description['transaction-type']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.block')}
        value={data?.['block-id']}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.seed')}
        value={data?.description.seed}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.signature')}
        value={data?.description.signature}
        wide
        loading={isLoading}
      />
      <CardRow
        name={t('labels.digest')}
        value={data?.['digest']}
        wide
        loading={isLoading}
      />
      {data?.description && (
        <>
          {'from' in data.description && (
            <CardRow
              name={t('labels.fromAddress')}
              value={data.description.from}
              wide
              loading={isLoading}
            />
          )}
          {'to' in data.description && (
            <CardRow
              name={t('labels.toAddress')}
              value={data.description.to}
              wide
              loading={isLoading}
            />
          )}
        </>
      )}
      {amountData && (
        <CardRow
          name={t('labels.amount')}
          value={`${formatAmount(amountData.amount)} ${amountData.asset || getNameByCode(amountData.code!)}`}
          wide
          loading={isLoading}
        />
      )}
      {data?.description && 'description' in data.description && (
        <CardRow
          name={t('labels.description')}
          value={data.description.description}
          wide
          loading={isLoading}
        />
      )}
    </div>
  );
};
