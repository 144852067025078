import { Route } from 'react-justanother-router';

import { RouterName } from '@app/router/types';
import { BlockPage, BlocksPage } from '@pages/Blocks';
import { ExplorerPage } from '@pages/Explorer';
import { HomePage } from '@pages/Home';
import { PageNotFound } from '@pages/Static';
import { StatsPage } from '@pages/Stats';
import { TransactionPage, TransactionsPage } from '@pages/Transactions';
import { WalletsPage } from '@pages/Wallets';
import { BaseLayout, HeaderProps } from '@widgets/Layout';

export const routes: Route[] = [
  {
    component: HomePage,
    name: RouterName.Home,
    path: '/',
    props: {
      header: {
        hideSearch: true,
        static: true,
        transparent: true,
      } as HeaderProps,
      layout: BaseLayout,
    },
  },
  {
    component: ExplorerPage,
    name: RouterName.Explorer,
    path: '/explorer',
    props: {
      layout: BaseLayout,
    },
  },
  {
    children: [
      {
        component: BlockPage,
        name: RouterName.Block,
        path: '/:id',
        props: {
          layout: BaseLayout,
        },
      },
    ],
    component: BlocksPage,
    name: RouterName.BlocksList,
    path: '/blocks',
    props: {
      layout: BaseLayout,
    },
  },
  {
    children: [
      {
        component: TransactionPage,
        name: RouterName.Transaction,
        path: '/:id',
        props: {
          layout: BaseLayout,
        },
      },
    ],
    component: TransactionsPage,
    name: RouterName.TransactionsList,
    path: '/transactions',
    props: {
      layout: BaseLayout,
    },
  },
  {
    component: WalletsPage,
    name: RouterName.WalletsList,
    path: '/wallets',
    props: {
      layout: BaseLayout,
    },
  },
  {
    component: StatsPage,
    name: RouterName.Stats,
    path: '/stats',
    props: {
      layout: BaseLayout,
    },
  },
  {
    component: PageNotFound,
    name: RouterName.NotFound,
    path: '(.*)',
  },
];
