import { FC } from 'react';
import cx from 'classnames';

import { getRandomArbitrary, Skeleton } from '@/shared';

import styles from './styles.module.scss';

export const JettonSkeleton: FC = () => (
  <div className={cx(styles.jetton, styles.jetton_skeleton)}>
    <div className={styles.jetton__img}>
      <Skeleton height={36} />
    </div>

    <div className={styles.jetton__textSkeleton}>
      <div>
        <Skeleton inline height={16} className={styles.jetton__title}>
          {Array.from({ length: getRandomArbitrary(6, 14) })
            .fill(1)
            .join('')}
        </Skeleton>
      </div>
      <div>
        <Skeleton inline className={styles.jetton__subtitle} height={16}>
          {Array.from({ length: getRandomArbitrary(10, 20) })
            .fill(1)
            .join('')}
        </Skeleton>
      </div>
    </div>
  </div>
);
