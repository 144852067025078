import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-justanother-router';

import { RouterName } from '@app/router';

import styles from './styles.module.scss';

export const PageNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.staticPage404}>
      <div className={styles.staticPage404__textWrapper}>
        <h1 className={styles.staticPage404__title}>404</h1>
        <h3 className={styles.staticPage404__subtitle}>
          {t('titles.pageNotFound')}
        </h3>
      </div>

      <div className={styles.staticPage404__footer}>
        <p>{t('labels.backTo')}</p>
        <Link className={styles.staticPage404__link} to={RouterName.Home}>
          {t('labels.mainPage')}
        </Link>
      </div>
    </div>
  );
};
