import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, WithCloseModal } from 'dekube-ui-kit';

import { DialogWrapper, Skeleton } from '@/shared';
import { WalletBalanceItem } from '@entities/Wallets';
import { api } from '@shared/libs/api';
import { QueryKey } from '@shared/libs/types';
import { useQuery } from '@tanstack/react-query';

export interface BalanceWalletModalProps {
  publicKey: string;
}
declare global {
  interface ModalProps {
    balanceWallet: BalanceWalletModalProps;
  }
}
export const BalanceWalletModal: FC<
  WithCloseModal<BalanceWalletModalProps>
> = ({ onClose, publicKey }) => {
  const { t } = useTranslation();
  const { data: walletData, isLoading } = useQuery({
    queryFn: () => api.getWalletState({ publicKey }),
    queryKey: [QueryKey.Wallet, publicKey],
  });
  const hasBalance = !!walletData?.balance?.length;

  return (
    <DialogWrapper title={t('titles.walletBalances')} onClose={onClose}>
      {isLoading ? (
        <Skeleton height={42} />
      ) : hasBalance ? (
        <>
          {(walletData?.balance || []).map((el) => (
            <WalletBalanceItem
              key={el.code}
              code={el.code}
              amount={+el.amount}
            />
          ))}
        </>
      ) : (
        <Typography variant="l-medium" color="blue-main">
          {t('validations.walletBalanceEmpty')}
        </Typography>
      )}
    </DialogWrapper>
  );
};
