import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import { TransactionInfo } from '@widgets/Transactions';

import styles from './styles.module.scss';

export interface TransactionPageProps {
  params: {
    id: string;
  };
}
export const TransactionPage: FC<TransactionPageProps> = ({ params }) => {
  const id = +params.id;
  const { t } = useTranslation();

  return (
    <div className={styles.block}>
      <Card titlePy={2} titlePx={2} title={t('titles.transactionDetails')}>
        <TransactionInfo id={id} />
      </Card>
    </div>
  );
};
