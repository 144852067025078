export const TOKENS_NAMES: Record<string, { name: string; fullname: string }> =
  {
    ['4090']: {
      fullname: 'RTX4090',
      name: '4090',
    },
    ['A100']: {
      fullname: 'A100TON',
      name: 'A100',
    },
    ['btc']: {
      fullname: 'Bitcoin',
      name: 'BTC',
    },
    ['eth']: {
      fullname: 'Ethereum',
      name: 'ETH',
    },
    ['gaus']: {
      fullname: 'Gaus token',
      name: 'GAUSS',
    },
    ['gt18']: {
      fullname: 'GameToken18',
      name: 'GT18',
    },
    ['kube']: {
      fullname: 'Kube token',
      name: 'KUBE',
    },
    ['mega']: {
      fullname: 'Mega token',
      name: 'MEGA',
    },
    ['usdt']: {
      fullname: 'USDT',
      name: 'USDT (TRC-20)',
    },
  };
