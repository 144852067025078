import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/shared';
import { WalletsTable } from '@entities/Wallets';
import { MainStatsNumbers } from '@widgets/Stats';

import styles from './styles.module.scss';

export const WalletsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wallets}>
      <MainStatsNumbers />
      <Card titlePy={1} titlePx={2} title={t('titles.wallets')}>
        <WalletsTable />
      </Card>
    </div>
  );
};
